var render = function () {
  var _vm$report;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "mb-1",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "py-1"
  }, [_c('h4', {
    staticClass: "card-title font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t('reports')) + " ")])]), _c('div', {
    staticClass: "px-1"
  }, [_c('b-row', {
    staticClass: "px-1 pb-1 pt-0"
  }, [_c('b-col', {
    staticClass: "text-dark bg-blue-gradient border-info rounded flex-column p-1",
    attrs: {
      "md": "6"
    }
  }, [_c('h2', {
    staticClass: "text-dark"
  }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.report.total')) + " : " + _vm._s((_vm$report = _vm.report) === null || _vm$report === void 0 ? void 0 : _vm$report.total) + " " + _vm._s(_vm.$t('resellTicket.report.totalID')) + " ")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column px-3 py-1"
  }, [_c('span', {
    staticClass: "text-info h4"
  }, [_vm._v(_vm._s(_vm.$t('resellTicket.note.title')))]), _c('span', {
    staticClass: "text-dark"
  }, [_vm._v("- " + _vm._s(_vm.$t('resellTicket.note.note1')))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("- " + _vm._s(_vm.$t('resellTicket.note.note2')))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("- " + _vm._s(_vm.$t('resellTicket.note.note4')))])])])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }